import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainComponent from './MainComponent';
import NotFound from './NotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/registration/:id' element={<MainComponent />} />
        <Route path='/:id' element={<MainComponent />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
